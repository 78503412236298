<template>
  <div>
    <b-row>
      <b-col lg="8">
        <p class="text-green ma-28-semi mb-4 w-100">{{ $t('feature-info2') }}</p>
        <p>{{ $t('feature-info2-content') }}</p>
        <p class="text-green ma-28-semi mb-4 w-100">{{ $t('feature-info2-title') }}</p>
        <p>{{ $t('feature-info2-title-content') }}</p>
        <p class="text-green ma-28-semi mb-4 w-100">{{ $t('feature-info2-title2') }}</p>
        <p>{{ $t('feature-info2-title2-content') }}</p>
        <p class="text-green ma-28-semi mb-4 w-100">{{ $t('feature-info2-title3') }}</p>
        <p>{{ $t('feature-info2-title3-content') }}</p>
        <p class="text-green ma-28-semi mb-4 w-100">{{ $t('feature-info2-title4') }}</p>
        <p>{{ $t('feature-info2-title4-content') }}</p>
        <p class="text-green ma-28-semi mb-4 w-100">{{ $t('feature-info2-title5') }}</p>
        <p>{{ $t('feature-info2-title5-content') }}</p>
        <!-- <p><strong class="mr-2">{{ $t('step1') }}</strong>{{ $t('router-search-1') }}</p>
        <div class="text-center">
          <img src="/assets/image/guide/guide_6.jpg" height="512px">
        </div>
        <p><strong class="mr-2">{{ $t('step2') }}</strong>{{ $t('router-search-2') }}</p>
        <div class="text-center">
          <img src="/assets/image/guide/guide_7.jpg" height="512px">
        </div>
        <div class="text-center">
          <img src="/assets/image/guide/guide_8.jpg" height="512px">
        </div> -->
      </b-col>
      <b-col lg="4">
        <RightMenu />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RightMenu from "@/layout/RightMenu";
export default {
  components: {
    RightMenu,
  },
};
</script>

<style scoped>
.font-64 {
  font-size: 64px;
}
.font-24 {
  font-size: 24px;
}
.title-block {
  font-size: 12px;
  font-weight: 300;
  color: #636466;
  width: 75%;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  width: 75%;
  margin-bottom: 40px !important;
}
.content-slogan {
  color: #c71313;
  font-size: 48px !important;
  width: 50%;
  margin: 16px 0 !important;
}
.content-card {
  border: 1px solid #e6e8ec;
  border-radius: 24px;
  overflow: hidden;
  transition: 500ms;
  cursor: pointer;
}
.content-card p {
  height: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.content-card:hover {
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
}
</style>
